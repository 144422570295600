import { Component } from 'react'
import axios from 'axios'

import Scrollbar from 'react-scrollbars-custom'
import { withAuth } from 'react-oidc-context'
import { CanvasItem } from '../models/canvasItem.model'
import { CanvasConnection } from '../models/canvasConnection.model'
import LoadingSpinner from './loadingSpinner.component'
import { SignalInterface } from '../enums/signalInterface.enum'

interface EditorProps {
  projectId: string
}

interface EditorState {
  projectId: string
  canvas: draw2d.Canvas | undefined
  isFetchingCanvas: boolean
}

export interface CanvasObjects {
  projectId: string
  items: CanvasItem[]
  connections: CanvasConnection[]
}

class Editor extends Component<EditorProps, EditorState> {
  private canvas: draw2d.Canvas | undefined

  constructor(props: EditorProps) {
    super(props)
    this.state = {
      projectId: props.projectId,
      canvas: undefined,
      isFetchingCanvas: true,
    }
  }

  async componentDidUpdate(): Promise<void> {
    const { isFetchingCanvas } = this.state
    if (!isFetchingCanvas) {
      return
    }
    const { projectId } = this.state
    this.canvas = new draw2d.Canvas('gfx_holder')
    this.canvas.installEditPolicy(new draw2d.policy.canvas.FadeoutDecorationPolicy())
    this.canvas.installEditPolicy(
      new draw2d.policy.connection.DragConnectionCreatePolicy({
        createConnection: this.createConnection,
      })
    )

    // remove the default keyboard handler
    this.canvas.uninstallEditPolicy('draw2d.policy.canvas.DefaultKeyboardPolicy')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.canvas.installEditPolicy(new PlantypusKeyboardPolicy())
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.canvas.installEditPolicy(new DblClickCanvasPolicy())

    this.canvas.on('figure:remove', (_emitter, event) => {
      const { id } = event.figure
      const isConnection =
        event.figure.cssClass === 'draw2d_Connection' ||
        event.figure.cssClass === 'ConnectionWithLabel'

      const url = isConnection
        ? `${process.env.REACT_APP_BACKEND_URI}/v1/canvas/connection/${id}`
        : `${process.env.REACT_APP_BACKEND_URI}/v1/canvas/device/${id}`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth } = this.props
      const token = auth.user?.access_token
      const authorizationHeader = token ? `Bearer ${token}` : ''

      axios({
        method: 'delete',
        url,
        headers: {
          Authorization: authorizationHeader,
        },
      })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
    this.canvas.on('click', () => {
      this.saveCanvas()
    })

    const items = await this.getCanvas(projectId)

    const canvasObjects = [...items.items, ...items.connections]

    const reader = new draw2d.io.json.Reader()
    reader.unmarshal(this.canvas, canvasObjects)

    this.setState({ canvas: this.canvas })
  }

  public async getCanvas(projectId: string): Promise<CanvasObjects> {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/canvas/${projectId}`
    const canvasObjects: CanvasObjects = { projectId: '', items: [], connections: [] }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth } = this.props
    const token = auth.user?.access_token
    const authorizationHeader = token ? `Bearer ${token}` : ''

    await axios({
      method: 'get',
      url,
      headers: {
        Authorization: authorizationHeader,
      },
    })
      .then((res) => {
        const returnData = res.data as CanvasObjects
        Object.assign(canvasObjects, returnData)
        this.setState({ isFetchingCanvas: false })
      })
      .catch((err) => {
        console.log(err)
      })

    return canvasObjects
  }

  private createConnection(): draw2d.Connection {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const con = new ConnectionWithLabel()

    con.setRouter(new draw2d.layout.connection.InteractiveManhattanConnectionRouter())

    const color = new draw2d.util.Color(0, 0, 0)
    con.setColor(color)

    con.on('connect', (emitterPort: draw2d.Port) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const port = emitterPort.sourcePort
      const signalInterface = port.semanticGroup as SignalInterface

      port.getConnections().each((_i: number, conn: draw2d.Connection) => {
        let connectionColor
        switch (signalInterface) {
          case SignalInterface.SDI:
            connectionColor = '#00a900'
            break
          case SignalInterface.HDMI:
            connectionColor = '#a90000'
            break
          case SignalInterface.XLR:
            connectionColor = '#8c00b0'
            break
          case SignalInterface.KLINKE_3_5:
            connectionColor = '#0174a1'
            break
          case SignalInterface.KLINKE_6_3:
            connectionColor = '#540badff'
            break
          case SignalInterface.ETHERNET:
            connectionColor = '#e5c100'
            break
          default:
            connectionColor = '#000000'
            break
        }
        conn.setColor(connectionColor)
      })
    })
    return con
  }

  private saveCanvas(): void {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/canvas/`

    const { projectId } = this.state
    const writer = new draw2d.io.json.Writer()
    const { canvas } = this.state
    writer.marshal(canvas, async (canvasItems: CanvasItem[]) => {
      const canvasUpdateDto = {
        projectId,
        items: [],
        connections: [],
      } as CanvasObjects
      canvasItems.forEach((canvasObject) => {
        if (
          canvasObject.type === 'draw2d.Connection' ||
          canvasObject.type === 'ConnectionWithLabel'
        ) {
          const canvasConnection = canvasObject as unknown as CanvasConnection
          canvasConnection.x = 0
          canvasConnection.y = 0
          if (!canvasConnection.labels) {
            canvasConnection.labels = []
          }
          canvasUpdateDto.connections.push(canvasConnection)
        } else {
          canvasUpdateDto.items.push(canvasObject)
        }
      })

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth } = this.props
      const token = auth.user?.access_token
      const authorizationHeader = token ? `Bearer ${token}` : ''

      await axios({
        method: 'PUT',
        url,
        headers: {
          Authorization: authorizationHeader,
        },
        data: canvasUpdateDto,
      })
    })
  }

  render(): JSX.Element {
    const { isFetchingCanvas } = this.state
    return (
      <Scrollbar className="bg-white row-start-2 row-end-6 col-span-2 col-end-5 relative">
        {isFetchingCanvas ? <LoadingSpinner /> : null}
        <div id="gfx_holder" style={{ height: 3000, width: 3000 }} />
      </Scrollbar>
    )
  }
}

export default withAuth(Editor)
