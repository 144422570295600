import { useAuth, withAuth } from 'react-oidc-context'
import plantypusLogo from '../assets/img/plantypus_logo.png'

function Header(): JSX.Element {
  const auth = useAuth()

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>
    case 'signoutRedirect':
      return <div>Signing you out...</div>
    default:
      break
  }

  if (auth.isLoading) {
    return <div>Loading...</div>
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>
  }
  if (!auth.isAuthenticated) {
    const originPath = window.location.pathname
    auth.signinRedirect({
      redirect_uri: process.env.REACT_APP_FRONTEND_URI?.concat(originPath),
    })
    return <div>Redirecting...</div>
  }
  window.history.replaceState({}, document.title, window.location.pathname)

  return (
    <div className="bg-blue-main col-span-5 flex flex-row justify-between items-center p-10">
      <div className="flex flex-row items-center text-white font-light text-2xl xl:text-3xl">
        <div>
          <a href="/dashboard">
            <img src={plantypusLogo} alt="Plantypus Logo" width={75} height={75} />
          </a>
        </div>
        <a href="/dashboard">
          <p style={{ fontFamily: 'Quicksand', fontWeight: 500, letterSpacing: 2 }}>PLANTYPUS</p>
        </a>
      </div>
      <div
        className="flex flex-row justify-around items-center space-x-10 text-white font-light text-base
        xl:text-xl"
      >
        <div>{auth.user?.profile?.name}</div>
        <div>
          <button
            type="button"
            onClick={() => {
              auth.removeUser()
              auth.signoutRedirect()
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

export default withAuth(Header)
