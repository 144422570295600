export enum PortType {
  SDI_INPUT = 'SdiIn',
  SDI_OUTPUT = 'SdiOut',
  HDMI_INPUT = 'HdmiIn',
  HDMI_OUTPUT = 'HdmiOut',
  KLINKE_3_5_INPUT = 'Klinke_3_5_in',
  KLINKE_3_5_OUTPUT = 'Klinke_3_5_out',
  KLINKE_6_3_INPUT = 'Klinke_6_3_in',
  KLINKE_6_3_OUTPUT = 'Klinke_6_3_out',
  XLR_INPUT = 'XlrIn',
  XLR_OUTPUT = 'XlrOut',
  ETHERNET = 'EthernetHybridPort',
}
