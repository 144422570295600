import { Port } from '../models/port.model'
import { PortType } from '../enums/portType.enum'

const PortInCanvasFactory = (port: Port): draw2d.InputPort | draw2d.OutputPort => {
  let portInCanvas: draw2d.Port
  const props = { signalShortName: port.signalShortName, name: port.signalShortName }
  const signalLabel = new draw2d.shape.basic.Label({ text: port.signalShortName })
  signalLabel.setStroke(0)

  switch (port.type) {
    case PortType.SDI_INPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new SdiIn(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.RightLocator())
      break
    case PortType.SDI_OUTPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new SdiOut(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.LeftLocator())
      break
    case PortType.HDMI_INPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new HdmiIn(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.RightLocator())
      break
    case PortType.HDMI_OUTPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new HdmiOut(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.LeftLocator())
      break
    case PortType.KLINKE_3_5_INPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new Klinke_3_5_in(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.RightLocator())
      break
    case PortType.KLINKE_3_5_OUTPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new Klinke_3_5_out(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.LeftLocator())
      break
    case PortType.KLINKE_6_3_INPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new Klinke_6_3_in(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.RightLocator())
      break
    case PortType.KLINKE_6_3_OUTPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new Klinke_6_3_out(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.LeftLocator())
      break
    case PortType.XLR_INPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new XlrIn(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.RightLocator())
      break
    case PortType.XLR_OUTPUT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new XlrOut(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.LeftLocator())
      break
    case PortType.ETHERNET:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas = new EthernetHybridPort(props)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      portInCanvas.add(signalLabel, new draw2d.layout.locator.LeftLocator())
      break
    default:
      portInCanvas = new draw2d.HybridPort()
      portInCanvas.setName(port.signalName)
      break
  }
  portInCanvas.setMaxFanOut(1)
  return portInCanvas
}

export default PortInCanvasFactory
