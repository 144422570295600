import { Link } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'

function Landing(): JSX.Element {
  const auth = useAuth()

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>
    case 'signoutRedirect':
      return <div>Signing you out...</div>
    default:
      break
  }

  if (auth.isLoading) {
    return <div>Loading...</div>
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>
  }
  if (!auth.isAuthenticated) {
    const originPath = window.location.pathname
    auth.signinRedirect({
      redirect_uri: process.env.REACT_APP_FRONTEND_URI?.concat(originPath),
    })
    return <div>Redirecting...</div>
  }

  window.history.replaceState({}, document.title, window.location.pathname)

  return (
    <div
      className="flex flex-col items-center justify-center h-screen space-x-10 bg-blue-main text-white font-light
      text-base  xl:text-xl leading-10"
    >
      <h1 className="font-bold">Welcome to PLANTYPUS</h1>

      <div>
        Hello {auth.user?.profile?.name} <br />
        <button type="button" onClick={() => auth.removeUser()}>
          Log out
        </button>
      </div>

      <p>
        Plantypus is your future signal planning web-app - no need to use draw.io or even
        Illustrator anymore.
      </p>
      <p>
        Just save your devices for the future in your personal library or make use of the already
        existing devices from various manufactures.
      </p>
      <p>
        Unfortunately, we are in a closed beta phase. If you wish to join and test plantypus
        already, please get in touch with us:
        <Link to="mailto:team@plantypus.de"> team@plantypus.de</Link>
      </p>
    </div>
  )
}

export default Landing
