import { Component } from 'react'
import axios from 'axios'
import { withAuth } from 'react-oidc-context'
import CreateDevice from '../../pages/createDevice'
import { leftChevron1, plus } from '../../assets/img'
import { Device } from '../../models/device.model'
import { CanvasObjects } from '../Editor.component'
import { CanvasItem } from '../../models/canvasItem.model'
import { CanvasConnection } from '../../models/canvasConnection.model'
import PortInCanvasFactory from '../../shared/PortInCanvasFactory'

interface DeviceLibraryProps {
  setShowDeviceLibrary: (value: boolean) => void
  projectId: string
}

interface DeviceLibraryState {
  showCreateDevice: boolean
  deviceLibrary: Device[]
  categories: string[]
  categoryOpen: Map<string, boolean>
  projectId: string
}
class DeviceLibraryModal extends Component<DeviceLibraryProps, DeviceLibraryState> {
  private readonly setShowDeviceLibrary: (value: boolean) => void

  constructor(props: DeviceLibraryProps) {
    super(props)
    this.state = {
      showCreateDevice: false,
      deviceLibrary: [],
      categories: [],
      categoryOpen: new Map<string, boolean>(),
      projectId: props.projectId,
    }
    this.setShowDeviceLibrary = props.setShowDeviceLibrary
    this.getAllDevices()
  }

  private setShowCreateDevice = (): void => {
    this.setState({ showCreateDevice: false })
  }

  private async getAllDevices(): Promise<void> {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/device/`

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth } = this.props
    const token = auth.user?.access_token
    const authorizationHeader = token ? `Bearer ${token}` : ''

    const { categoryOpen } = this.state

    await axios({
      method: 'get',
      url,
      headers: {
        Authorization: authorizationHeader,
      },
    }).then((res) => {
      const deviceLibrary = res.data as Device[]
      const categories: string[] = []
      // Loop through all devices and add their categories to the categories array
      deviceLibrary.forEach((device) => {
        device.categories.forEach((category) => {
          if (!categories.includes(category)) {
            categories.push(category)
            categoryOpen.set(category, false)
          }
        })
      })
      this.setState({ deviceLibrary, categories })
    })
  }

  private getDeviceComponent(device: Device): JSX.Element {
    return (
      <div className="p-2 text-sm cursor-pointer" key={device.uuid}>
        <button
          type="button"
          onClick={() => this.addDeviceToCanvas(device)}
        >{`${device.manufacturer} ${device.model}`}</button>
      </div>
    )
  }

  private getCategorySection(category: string): JSX.Element {
    const { deviceLibrary, categoryOpen } = this.state

    const closedOptions = {
      switcherSection: 'flex flex-row items-center justify-between p-2',
      arrowSwitcher: 'h-4 xl:h-5 w-4 xl:w-5 cursor-pointer transform -rotate-90',
      switcherSelection: '',
      switcherSectionWrapper: 'flex flex-col border border-grey-light rounded-md mb-4',
    }
    const openOptions = {
      switcherSection: 'flex flex-row items-center justify-between p-2',
      arrowSwitcher: 'h-4 xl:h-5 w-4 xl:w-5 cursor-pointer transform rotate-90',
      switcherSelection: 'h-fit p-2',
      switcherSectionWrapper:
        'flex flex-col border border-grey-light rounded-md mb-4 divide-y divide-grey-light p-2',
    }

    return (
      <div key={`${category}CategorySectionDiv`}>
        <div
          id={`${category}SwitcherSection`}
          className={
            categoryOpen.get(category) ? openOptions.switcherSection : closedOptions.switcherSection
          }
        >
          <div className="sm:w-max xl:w-4/5">{category}</div>
          <button
            type="button"
            onClick={() => {
              categoryOpen.set(category, !categoryOpen.get(category))
              this.setState({ categoryOpen })
            }}
          >
            <img
              id={`${category}ArrowSwitcher`}
              alt=""
              className={
                categoryOpen.get(category) ? openOptions.arrowSwitcher : closedOptions.arrowSwitcher
              }
              src={leftChevron1}
            />
          </button>
        </div>
        <div
          id={`${category}SwitcherSelection`}
          className={
            categoryOpen.get(category)
              ? openOptions.switcherSelection
              : closedOptions.switcherSelection
          }
        >
          {categoryOpen.get(category)
            ? deviceLibrary
                // Filter for all devices in the category and map those to the device component
                .filter((device) => device.categories.includes(category))
                .map((device) => this.getDeviceComponent(device))
            : null}
        </div>
      </div>
    )
  }

  private async addDeviceToCanvas(device: Device): Promise<void> {
    const { projectId } = this.state
    const headlessCanvas = new draw2d.HeadlessCanvas()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canvasDevice = new DeviceInCanvas({
      x: 100,
      y: 100,
      width: device.width,
      height: device.height,
      color: 'rgba(27,27,27,1)',
      bgColor: 'rgba(160,160,160,1)',
      userData: {
        manufacturer: device.manufacturer,
        model: device.model,
      },
    })

    const { manufacturer, model } = device
    const label = new draw2d.shape.basic.Label({ text: `${manufacturer}\n${model}` })
    label.setStroke(0)
    canvasDevice.add(label, new draw2d.layout.locator.CenterLocator())

    device.ports.forEach((port) => {
      const portInCanvas = PortInCanvasFactory(port)
      canvasDevice.addPort(portInCanvas)
    })

    headlessCanvas.add(canvasDevice, 200, 200)
    const writer = new draw2d.io.json.Writer()
    writer.marshal(headlessCanvas, async (canvasItems: CanvasItem[]) => {
      const canvasUpdateDto = {
        projectId,
        items: [],
        connections: [],
      } as CanvasObjects
      canvasItems.forEach((canvasObject) => {
        if (canvasObject.type === 'draw2d.Connection') {
          canvasUpdateDto.connections.push(canvasObject as unknown as CanvasConnection)
        } else {
          canvasUpdateDto.items.push(canvasObject)
        }
      })

      const url = `${process.env.REACT_APP_BACKEND_URI}/v1/canvas/`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth } = this.props
      const token = auth.user?.access_token
      const authorizationHeader = token ? `Bearer ${token}` : ''

      await axios({
        method: 'PUT',
        url,
        headers: {
          Authorization: authorizationHeader,
        },
        data: canvasUpdateDto,
      })

      // Needs to reload, because the component cannot be updated yet
      window.location.reload()
    })
  }

  render(): JSX.Element {
    const { showCreateDevice, categories } = this.state
    return (
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-2 px-4 pb-20 text-center xl:block xl:pt-24 p-0">
          <div
            className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"
            aria-hidden="true"
          />

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>

          {showCreateDevice ? (
            <CreateDevice setShow={this.setShowCreateDevice} />
          ) : (
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left text-base xl:text-lg overflow-hidden
          shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-xl sm:w-full"
            >
              <div className="bg-blue-main px-6 pt-7 sm:px-8 sm:pt-8">
                <div className="flex items-start flex-col">
                  <div className="text-white">Select a device</div>
                  <div
                    className="grid grid-cols-5 grid-rows-2 rounded-md my-8 align-middle max-w-screen-xl
                w-full h-full"
                  >
                    <div
                      className="col-start-0 col-end-1 row-start-0 mr-8 bg-white text-black rounded-md
                    px-6 p-8 h-60"
                    >
                      <div className="col-start-0 col-end-1 flex flex-row justify-between mb-5 ">
                        <div className="sm:w-max xl:w-4/5">My Device Library</div>
                        <button
                          id="plus"
                          type="button"
                          onClick={() => this.setState({ showCreateDevice: true })}
                          className="h-4 xl:h-6 w-4 xl:w-6 cursor-pointer text-lg font-light"
                        >
                          <img alt="" src={plus} />
                        </button>
                      </div>
                      <div className="text-center">
                        <i className="text-center">
                          Your personal device library <br />
                          will come in future versions of plantypus!
                        </i>
                      </div>
                    </div>
                    <div
                      className="col-start-1 col-end-5 row start-0 row-span-2 bg-white text-black rounded-md px-6 p-8
                  h-auto"
                    >
                      <div className="sm:w-max xl:w-4/5 pb-2">General Device Library</div>
                      <div
                        id="switcherSectionWrapper"
                        className="flex flex-col border border-grey-light rounded-md mb-4 divide-y divide-grey-light p-2"
                      >
                        {categories.map((category) => this.getCategorySection(category))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-blue-main px-6 pb-7 sm:px-8 sm:flex sm:flex-row sm:justify-between">
                <button
                  onClick={() => this.setShowDeviceLibrary(false)}
                  type="button"
                  className="w-full inline-flex justify-center bg-transparent border border-transparent text-white
                font-light cursor-pointer hover:text-grey-dark sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default withAuth(DeviceLibraryModal)
