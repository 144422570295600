import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import axios from 'axios'
import plus from '../assets/img/svg/plus.svg'
import CreateProject from './createProject'
import Header from '../components/header.component'
import projectSampleImage from '../assets/img/project-sample.png'
import LoadingSpinner from '../components/loadingSpinner.component'

interface Project {
  name: string
  uuid: string
  owner: string
}
function Dashboard(): JSX.Element {
  const [showCreateProject, setShowCreateProject] = useState(false)
  const [isFetchingProjects, setIsFetchingProjects] = useState(true)
  const [projects, setProjects] = useState<Project[]>([])
  const auth = useAuth()
  const openProject = (uuid: string): void => {
    window.location.href = `/project/${uuid}`
  }

  useEffect(() => {
    const token = auth.user?.access_token
    if (token === undefined) {
      return
    }
    const authorizationHeader = token ? `Bearer ${token}` : ''
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/project/`
    const fetchData = async (): Promise<void> => {
      await axios({
        method: 'get',
        url,
        headers: {
          Authorization: authorizationHeader,
        },
      })
        .then((res) => {
          const returnData = res.data as Project[]
          setProjects(returnData)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    fetchData().finally(() => {
      setIsFetchingProjects(false)
    })
  }, [auth])

  return (
    <div className="max-h-screen grid grid-rows-main">
      <Header />
      <div className="grid grid-row-projects grid-cols-projects text-base xl:text-lg font-light p-10">
        <div className="flex flex-row justify-between col-span-5">
          <div className="flex flex-row items-baseline">
            <h1 className="text-2xl ">Projects</h1>
            <div id="wrapperCreateProject">
              {showCreateProject ? <CreateProject setShow={setShowCreateProject} /> : ''}
            </div>
          </div>
        </div>
        <div className="flex flex-row col-span-5 overflow-x-auto">
          <div>
            <button
              style={{ width: '250px', height: '250px' }}
              type="button"
              className="flex items-center justify-center border border-grey-light rounded-xl m-5 p-20 w-52"
              onClick={() => setShowCreateProject(true)}
            >
              <img alt="" className="w-8 h-8 cursor-pointer" src={plus} />
            </button>
            <div id="container" className="flex flex-row flex-grow justify-evenly" />
          </div>
          {isFetchingProjects ? <LoadingSpinner /> : null}
          {projects.map((project) => (
            <div key={project.uuid} className="m-5">
              <div
                style={{ width: '250px', height: '250px' }}
                className="flex items-center justify-center border border-grey-light rounded-xl  w-52 p-5"
              >
                <button type="button" onClick={() => openProject(project.uuid)}>
                  <img alt="" className="cursor-pointer" src={projectSampleImage} />
                  <h2 className="ml-5 mt-8" style={{ textAlign: 'left', fontWeight: 800 }}>
                    {project.name}
                  </h2>
                  <p className="ml-5" style={{ textAlign: 'left', fontSize: '10pt' }}>
                    by {project.owner}
                  </p>
                </button>
                <div id="container" className="flex flex-row flex-grow justify-evenly" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
