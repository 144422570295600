import { Component } from 'react'
import axios from 'axios'
import { withAuth } from 'react-oidc-context'

interface CreateProjectProps {
  setShow: (value: boolean) => void
}

interface CreateProjectState {
  projectName: string
}

class CreateProject extends Component<CreateProjectProps, CreateProjectState> {
  private readonly setShow: (value: boolean) => void

  constructor(props: CreateProjectProps) {
    super(props)
    this.state = {
      projectName: '',
    }
    this.setShow = props.setShow
  }

  private createProject = async (): Promise<void> => {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/project/`
    const { projectName } = this.state

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth } = this.props
    const token = auth.user?.access_token
    const authorizationHeader = token ? `Bearer ${token}` : ''

    await axios({
      method: 'post',
      url,
      data: {
        name: projectName,
      },
      headers: {
        'Content-type': 'application/json',
        Authorization: authorizationHeader,
      },
    })
      .then((res) => {
        const projectData = res.data as {
          name: string
          uuid: string
        }
        window.location.href = `/project/${projectData.uuid}`
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render(): JSX.Element {
    return (
      <div>
        <div
          className="overflow-y-auto fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex xl:block justify-center items-end p-0 px-4 pt-2 xl:pt-24 pb-20 min-h-screen text-center">
            <div
              className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"
              aria-hidden="true"
            />
            <span className="hidden inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <div
              className="inline-block overflow-hidden sm:my-8 sm:w-full sm:max-w-screen-xl text-left align-bottom
          sm:align-middle bg-white rounded-lg shadow-xl transition-all transform"
            >
              <form>
                <div className="px-6 sm:px-8 pt-7 sm:pt-8 bg-blue-main">
                  <div className="flex flex-col items-start">
                    <div className="text-white">Add a project</div>
                    <div className="p-8 px-6 my-8 w-full max-w-screen-xl h-96 align-middle bg-white rounded-md">
                      <div className="flex flex-col sm:max-w-max">
                        <div className="flex flex-row justify-between pb-6">
                          <label className="pr-10" htmlFor="projectName">
                            Project Name
                            <input
                              onChange={(event) =>
                                this.setState({ projectName: event.target.value })
                              }
                              className="p-1 text-base xl:text-lg font-light rounded-md border border-1 focus:ring-1
                            border-grey-dark bg-grey-light placeholder-grey-dark focus:ring-blue-third ml-5"
                              type="text"
                              id="projectName"
                              name="projectName"
                              placeholder="Project Name"
                            />
                          </label>
                        </div>
                        {/*   <div className="flex flex-row justify-between pb-6">
                          <label className="pr-10" htmlFor="addUser">
                            Add User
                            <input
                              className="p-1 text-base xl:text-lg font-light rounded-md border border-4 focus:ring-1
                            border-grey-dark bg-grey-light placeholder-grey-dark focus:ring-blue-third"
                              type="text"
                              id="addUser"
                              name="coworker"
                              placeholder="Username"
                            />
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:flex sm:flex-row sm:justify-between px-6 sm:px-8 pb-7 bg-blue-main">
                  <button
                    onClick={() => this.setShow(false)}
                    type="button"
                    className="inline-flex justify-center w-full sm:w-auto font-light text-white border
                  border-transparent hover:text-grey-dark"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.createProject}
                    type="button"
                    value="submit"
                    className="inline-flex justify-center w-full sm:w-auto font-light text-white border
                  border-transparent hover:text-grey-dark"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withAuth(CreateProject)
