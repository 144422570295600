import { PortType } from '../enums/portType.enum'
import { SignalInterface } from '../enums/signalInterface.enum'

export class Port {
  signalName!: string

  signalShortName!: string

  type!: PortType

  signalInterface!: SignalInterface

  constructor(port: Port) {
    Object.assign(this, port)
  }
}
