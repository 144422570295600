import { useParams } from 'react-router-dom'
import { useState } from 'react'
import DeviceLibraryModal from '../components/modals/deviceLibrary.modal'
import { leftChevron1, plus } from '../assets/img'
import Editor from '../components/Editor.component'
import Header from '../components/header.component'

function Project(): JSX.Element {
  const params = useParams() as { projectId: string }
  const [showDeviceLibrary, setShowDeviceLibrary] = useState(false)

  return (
    <div className="absolute inset-0 overflow-hidden max-h-screen grid grid-rows-editor grid-cols-editor bg-blue-main">
      <Header />
      <div
        id="sectionAddDevice"
        className="row-start-2 col-start-1 col-span-2 bg-blue-secondary text-white text-base xl:text-xl font-light
        p-5 flex flex-row justify-between items-center"
      >
        <p id="textAddDevice" className="">
          Add Device
        </p>
        <div id="device" className="">
          <button
            type="button"
            id="plus"
            className="h-4 xl:h-6 w-4 xl:w-6 filter invert cursor-pointer text-lg font-light"
            onClick={() => setShowDeviceLibrary(true)}
          >
            <img alt="" src={plus} />
          </button>
          {showDeviceLibrary ? (
            <DeviceLibraryModal
              projectId={params.projectId}
              setShowDeviceLibrary={setShowDeviceLibrary}
            />
          ) : undefined}
        </div>
      </div>
      <div
        id="sectionProjectFiles"
        className="row-start-3 row-span-2 col-start-1 col-span-2 bg-blue-third text-white text-base xl:text-xl
        font-light p-5 flex items-start"
      >
        <div id="textProjectFiles" className="text-left">
          <p className="ml-10 mt-8 mb-8">
            Currently supported interfaces: <br /> <br />
            <span
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: '#3fe33f',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            />
            SDI <br />
            <span
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: '#e33f3f',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            />
            HDMI <br />
            <span
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: '#02b0f3',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            />
            Phone Connector 3.5mm
            <br />
            <span
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: '#8639e8',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            />
            Phone Connector 6.3mm
            <br />
            <span
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: '#C700FFFF',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            />
            XLR
            <br />
          </p>
          <h3 className="font-bold">Some Tips</h3>
          <p className="mt-5">
            Hold down shift and spin your scroll wheel to zoom in or out <br /> <br />
            Double click anywhere on the canvas to add a new label <br /> <br />
            Double click on a label to edit it <br /> <br />
            Double Click on a connection to add a label to it <br />
          </p>
        </div>
      </div>

      <div
        id="sectionArrowLeft"
        className="row-start-5 col-start-1 col-span-2 bg-blue-third flex items-center justify-end"
      >
        <img
          alt=""
          id="arrowLeft"
          className="h-4 xl:h-5 w-4 xl:w-5 m-5 filter invert cursor-pointer"
          src={leftChevron1}
        />
      </div>
      <Editor projectId={params.projectId} />
      <div
        id="sectionProperties"
        className="row-start-2 row-span-2 col-span-1 col-end-6 bg-blue-third text-white font-light text-sm xl:text-xl
        p-5 flex flex-col justify-start"
      >
        <p id="textProperties" className="transform rotate-90 mt-8">
          Properties
        </p>
      </div>
      <div
        id="sectionButtons"
        className="row-start-4 row-span-1 col-end-6 col-span-1 bg-blue-third text-white text-sm xl:text-xl font-light
        p-5 flex flex-col justify-between"
      >
        {/* Section for extra Buttons and interactions, see example here. Get the Icons from
         https://icons.getbootstrap.com/#usage

          <button type="button" id="textUpload" className="pb-5" style={{ width: 30, height: 30 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-arrow-up-square-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2
                      2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708
                      0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z"
              />
            </svg>
          </button>
       */}
      </div>

      <div
        id="sectionArrowRight"
        className="row-start-5 col-end-6 col-span-1 bg-blue-third flex items-center justify-center"
      >
        {/* onClick="toggleProperties() */}
        <img
          alt=""
          id="arrowRight"
          className="h-4 xl:h-5 w-4 xl:w-5 filter invert cursor-pointer"
          src={leftChevron1}
        />
      </div>
    </div>
  )
}

export default Project
